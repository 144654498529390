import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => {
  return (
    <Layout>
      <SEO
        title="Contact Us - National Partners"
        description="National Partners was founded by a team of highly qualified and experienced finance executives who are dedicated to providing the highest standards in the premium finance industry."
      />
      <div className="page-headline">
        <div className="hero-headline contact-page">
          <div className="container">
            <div className="section-heading text-center"></div>
          </div>
        </div>
      </div>
      <div className="page-content p-0"></div>
    </Layout>
  )
}

export default ContactPage
